<template>
  <div class="problem">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">注销用户</div>
    </div>
    <div class="box">
      <div class="title">为了您的账号安全，请验证您的手机号</div>
      <div class="input">
        <van-field v-model="mobile" maxlength="11" placeholder="请输入手机号" />
        <van-field v-model="sms" center clearable placeholder="请输入验证码">
          <template #button>
            <van-button @click="getSms" size="small" type="info">{{
              !!flag ? second + "s" : tip
            }}</van-button>
          </template>
        </van-field>
      </div>
      <div class="checkBox">
        <van-checkbox v-model="checked"></van-checkbox>
        <div class="text">
          同意并签署 <span class="bule">《用户注销协议》</span> 与
          <span class="bule">《隐私政策》</span>
        </div>
      </div>
      <div class="submit" @click="postdestroy">注销</div>
    </div>
  </div>
</template>

<script>
import { mobile_send } from "@/api/login.js";
import { mobileTest, destroy } from "@/api/user.js";
export default {
  data() {
    return {
      sms: "",
      tip: "获取验证码",
      second: 60,
      flag: false,
      userInfo: {},
      mobile: "",
      checked: false,
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo")).data;
    // this.mobileshow =
    //   this.userInfo.mobile.substring(0, 3) +
    //   "****" +
    //   this.userInfo.mobile.substr(this.userInfo.mobile.length - 4);
  },
  methods: {
    gopath(val) {
      if (val == "back") {
        this.$router.go(-1);
      } else {
        // this.$router.push({
        //   path: "/me/detail",
        //   query: { commonProblemId: val },
        // });
      }
    },
    getSms() {
      if (this.flag) {
        return;
      }
      var patt = /^[1][3-9][\d]{9}/;
      if (!patt.test(this.mobile)) {
        this.$toast("请正确输入手机号");
        return;
      }
      this.flag = true;
      let that = this;
      var timeer = setInterval(function () {
        if (that.second == 0) {
          that.flag = false;
          that.second = 60;
          clearInterval(timeer);
        }
        that.second--;
      }, 1000);

      let data = {
        mobile: this.userInfo.mobile,
        type: 10,
      };
      mobile_send(data).then((res) => {});
    },
    async postdestroy() {
      if (!this.checked) {
        this.$toast("请同意并签署 《用户注销协议》 与 《隐私政策》");
        return;
      }
      let obj = {
        mobile: this.mobile,
        smsCode: this.sms,
      };

      await mobileTest(obj).then((res) => {
        if (res.data.code == 0) {
          destroy().then((res) => {
            this.$toast.success("注销成功");
            this.$router.replace("/");
          });
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
      color: #141414;
    }
  }
  .title {
    width: 85%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.box {
  background: #f8f8f8;
  width: 100%;
  min-height: 93.5vh;
  text-align: center;
  padding: 48px 14px 0 14px;
  box-sizing: border-box;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
    margin: 5px 0 38px 0;
  }
  .checkBox {
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 20px;
    .text {
      margin-left: 5px;
      .bule {
        color: #5f7dff;
      }
    }
  }
  .van-cell {
    margin-bottom: 5px;
    border-radius: 5px;
    .van-button {
      background: #fff;
      color: #5f7dff;
      border-right: none;
      border-bottom: none;
      border-top: none;
      border-color: #dddddd;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
  .submit {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background: #5f7dff;
    color: #fff;
    padding: 10px 0;
    border-radius: 5px; /*no*/
    margin-top: 40%;
  }
}

.van-button {
  width: 100px;
}
</style>